/* eslint-disable react-hooks/exhaustive-deps */

import React, {createContext, ReactNode, useState} from 'react';
import {IBikeCalculatorValues} from "../../helpers/bike-calculations";
import { TenantInvitationInfo, TenantVersion } from "../../requests/tenant/dto";

import {toast} from "sonner";
import {basePortalURL} from "../../routes";
import {useNavigate} from "react-router";

interface ContextProviderProps {
  children: ReactNode;
}

export interface ApplyCtx {
  setTenantData: (value: TenantInvitationInfo) => void;
  setDataCalculator: (value: IBikeCalculatorValues) => void;
  handleApply: () => void;
};

const initialCtxState: ApplyCtx = {
  setTenantData: (value: TenantInvitationInfo) => Promise.resolve(),
  setDataCalculator: (value: IBikeCalculatorValues) => Promise.resolve(),
  handleApply: () => Promise.resolve(),
};

const ApplyCtx = createContext<ApplyCtx>(initialCtxState);

export const useApplyCtx = () => React.useContext(ApplyCtx);

export const ApplyProvider = ({ children }: ContextProviderProps) => {
  const navigate = useNavigate();
  const [dataCalculator, setDataCalculator] = useState<IBikeCalculatorValues>();
  const [tenantDate, setTenantData] = useState<TenantInvitationInfo>();

  function isCertificateActive(
      certificateDatePeriodStartDate: string,
      certificateDatePeriodEndDate: string,
      certificateDatePeriodActive: boolean
  ): boolean {
    if (!certificateDatePeriodActive) {
      return false;
    }

    const startDate = new Date(certificateDatePeriodStartDate);
    const endDate = new Date(certificateDatePeriodEndDate);
    const currentDate = new Date();

    return currentDate >= startDate && currentDate <= endDate;
  }

  const handleApply = () => {
    if (!tenantDate) {
      return;
    }

    const {
      voucherLimit,
      voucherLimitSelfServe,
      certificateDatePeriodStartDate,
      certificateDatePeriodActive,
      certificateDatePeriodEndDate,
      isVoucherLimitUnlimited,
      isVoucherLimitUnlimitedSelfServe,
      tenantVersion,
    } = tenantDate;

    if (!dataCalculator) {
      toast.error('Calculator is empty. Could you fill in it please to continue.');

      return;
    }

    if (!isVoucherLimitUnlimited && tenantVersion !== TenantVersion.SELF_SERVE && voucherLimit < dataCalculator.voucherValue) {
      toast.error(`Your voucher limit is ${voucherLimit}. Consider asking your employer about increasing it.`);

      return;
    }

    if (!isVoucherLimitUnlimitedSelfServe && tenantVersion === TenantVersion.SELF_SERVE && voucherLimitSelfServe < dataCalculator.voucherValue) {
      toast.error(`Your voucher limit is ${voucherLimitSelfServe}. Consider asking your employer about increasing it.`);

      return;
    }

    if (isCertificateActive(certificateDatePeriodStartDate, certificateDatePeriodEndDate, certificateDatePeriodActive)) {
      toast.error(`The voucher application period is currently closed. For more information about future openings, please contact your scheme admin.`);

      return;
    }

    navigate(`${basePortalURL}/sal-sac-form?accountNumber=${tenantDate.companyAccountNumber}&params=${window.btoa(JSON.stringify({ ...dataCalculator, ...tenantDate }))}`);
  }

  return (
    <ApplyCtx.Provider
      value={{
        setTenantData,
        setDataCalculator,
        handleApply
      }}
    >
      {children}
    </ApplyCtx.Provider>
  );
};
