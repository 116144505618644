import AuthGuard from "../guards/authGuard/AuthGuard";
import {Navigate} from "react-router-dom";
import GuestGuard from "../guards/authGuard/GuestGaurd";
import React, {lazy} from "react";
import Loadable from "../layouts/full/shared/loadable/Loadable";

const VoucherDetails = Loadable(lazy(() => import('../views/voucher-details/VoucherDetails')));
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const ViewWrapper = Loadable(lazy(() => import('./wrapper/ViewWrapper')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Settings = Loadable(lazy(() => import('../views/employee-portal/settings-page')));
const InviteEmployees = Loadable(lazy(() => import('../views/invite-employees')));

const VoucherList = Loadable(lazy(() => import('../views/employee-portal/voucher-list')));

const DashboardCards = Loadable(lazy(() => import('../views/dashboard/Main')));

export const baseEmployeeURL = process.env.REACT_APP_EMPLOYEE_PORTAL_ROUTE as string;

export const employeeRoutes = [
    {
        path: baseEmployeeURL,
        element: (
            <AuthGuard>
                <FullLayout />
            </AuthGuard>
        ),
        children: [
            { path: `${baseEmployeeURL}`, element: <Navigate to={`${baseEmployeeURL}/dashboard`} /> },
            {
                path: `${baseEmployeeURL}/dashboard`,
                exact: true,
                element: (
                    <ViewWrapper title="Dashboard" description="Dashboard page">
                        <DashboardCards />
                    </ViewWrapper>
                ),
            },
            {
                path: `${baseEmployeeURL}/apply-for-voucher`,
                exact: true,
                element: (
                    <ViewWrapper title="gogeta cycling" description="Apply for a voucher">
                        <InviteEmployees />
                    </ViewWrapper>
                ),
            },
            {
                path: `${baseEmployeeURL}/vouchers`,
                exact: true,
                element: (
                    <ViewWrapper title={'Vouchers'}>
                        <VoucherList />
                    </ViewWrapper>
                ),
            },
            {
                path: `${baseEmployeeURL}/account/settings`,
                exact: true,
                element: (
                    <ViewWrapper title="Settings">
                        <Settings />
                    </ViewWrapper>
                ),
            },
            {
                path: `${baseEmployeeURL}/vouchers/view-details/:id`,
                exact: true,
                element: (
                    <ViewWrapper title="Voucher Details">
                        <VoucherDetails />
                    </ViewWrapper>
                ),
            },
            {
                path: `${baseEmployeeURL}/account/settings`,
                exact: true,
                element: (
                    <ViewWrapper title="Settings">
                        <Settings />
                    </ViewWrapper>
                ),
            },
            {
                path: `${baseEmployeeURL}/404`,
                exact: true,
                element: (
                    <ViewWrapper title={'Error'}>
                        <Error />
                    </ViewWrapper>
                ),
            },
            { path: '*', exact: true, element: <Navigate to={`/404`} /> },
        ],
    },
    {
        path: '/',
        element: (
            <GuestGuard>
                <BlankLayout />
            </GuestGuard>
        ),
        children: [
            { path: `${baseEmployeeURL}/auth/login`, element: <Login /> },
        ],
    },
];
