import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */

const Refunds = Loadable(lazy(() => import('../views/employer-portal/refunds-list')));
const Vouchers = Loadable(lazy(() => import('../views/employer-portal/voucher-list')));
const PayrollSummary = Loadable(lazy(() => import('../views/employer-portal/payroll-summary')));
const Invoices = Loadable(lazy(() => import('../views/employer-portal/invoice-list')));
const Settings = Loadable(lazy(() => import('../views/account-settings/Settings')));
const HelpPage = Loadable(lazy(() => import('../views/help/HelpPage')));
const DashboardCards = Loadable(lazy(() => import('../views/dashboard/Main')));
const InviteEmployees = Loadable(lazy(() => import('../views/invite-employees')));
const ApplyPage = Loadable(lazy(() => import('../views/apply')));

// authentication
const EmployerSetPassword = Loadable(
  lazy(() => import('../views/authentication/auth1/EmployerSetPassword')),
);
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/auth1/ForgotPassword')));
const ConfirmEmail = Loadable(lazy(() => import('../views/authentication/auth1/ConfirmEmail')));

const TwoSteps = Loadable(lazy(() => import('../views/authentication/auth1/TwoSteps')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));
const VoucherDetails = Loadable(lazy(() => import('../views/voucher-details/VoucherDetails')));
const PdfFormPage = Loadable(lazy(() => import('../views/pdf-form/Form')));
const ApplyPackagePage = Loadable(lazy(() => import('../views/package-apply/PackageApply')));
const BikeNowPage = Loadable(lazy(() => import('../views/bike-now/BikeNowPage')));
const RetailerPortalPage = Loadable(
  lazy(() => import('../views/retailer-portal/RetailerPortalPage')),
);
const RetailerPortalVerifyVoucherAndRetailerPage = Loadable(
  lazy(
    () =>
      import(
        '../views/retailer-portal-verify-voucher-and-retailer/RetailerPortalVerifyVoucherAndRetailerPage'
      ),
  ),
);
const RetailerPortalRegistrationPage = Loadable(
  lazy(() => import('../views/retailer-portal-registration/RetailerPortalRegistrationPage')),
);

const EmployerPortalRegistrationPage = Loadable(
  lazy(() => import('../views/employer-portal-registration/EmployerPortalRegistrationPage')),
);

const RetailerPortalConfirmPickupPage = Loadable(
  lazy(() => import('../views/retailer-portal-confirm-pickup/RetailerPortalConfirmPickupPage')),
);
const RetailerPortalRedeemVoucherPage = Loadable(
  lazy(() => import('../views/retailer-portal-redeem-voucher/RetailerPortalRedeemVoucherPage')),
);
const Go = Loadable(lazy(() => import('../views/Go/Go')));

const ViewWrapper = Loadable(lazy(() => import('./wrapper/ViewWrapper')));

import AuthGuard from 'src/guards/authGuard/AuthGuard';
import GuestGuard from 'src/guards/authGuard/GuestGaurd';

export const isNotSubdomain = () => {
  const host = window.location.host;
  const subDomain = host.split('.')[0];

  if (subDomain == 'dev') {
    return true;
  }

  return host.split('.').length <= 2;
};

export const basePortalURL = process.env.REACT_APP_PORTAL_ROUTE as string;

const ExternalRedirect = ({ url }: { url: string }) => {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

export const b2bPages = [
  {
    path: '/',
    exact: true,
    element: <ExternalRedirect url={process.env.REACT_APP_LANDINGS_URL || ''} />,
  },
  {
    path: `/npl/:id`,
    exact: true,
    element: (
      <ViewWrapper title="Bike Now  | gogeta" description="Bike Now page">
        <BikeNowPage />
      </ViewWrapper>
    ),
  },
  {
    path: `/go/:id`,
    exact: true,
    element: (
      <ViewWrapper title="Bike Now  | gogeta" description="Bike Now page">
        <Go />
      </ViewWrapper>
    ),
  },
  {
    path: `/npl/:id`,
    exact: true,
    element: (
        <ViewWrapper title="Bike Now  | gogeta" description="Bike Now page">
          <BikeNowPage />
        </ViewWrapper>
    ),
  },
  {
    path: `/apply-now`,
    exact: true,
    element: (
      <ViewWrapper title="Apply Now | gogeta" description="Apply Now page">
        <ApplyPackagePage />
      </ViewWrapper>
    ),
  },
  { path: '*', element: <Navigate to={`/404`} /> },
  { path: `/404`, exact: true, element: <Error /> },
];

export const portalRoutes = [
  {
    path: `${basePortalURL}`,
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: `${basePortalURL}`, element: <Navigate to={`${basePortalURL}/dashboard`} /> },
      {
        path: `${basePortalURL}/dashboard`,
        exact: true,
        element: (
          <ViewWrapper title="Dashboard" description="Dashboard page">
            <DashboardCards />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/invite-employees`,
        exact: true,
        element: (
          <ViewWrapper title="Invite Employees" description="Invite Employees page">
            <InviteEmployees />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/apply`,
        exact: true,
        element: (
          <ViewWrapper title="Apply & Approve" description="Apply & Approve page">
            <ApplyPage />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/vouchers`,
        exact: true,
        element: (
          <ViewWrapper title="Voucher">
            <Vouchers />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/payroll-summary`,
        exact: true,
        element: (
          <ViewWrapper title="Payroll Summary">
            <PayrollSummary />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/invoices`,
        exact: true,
        element: (
          <ViewWrapper title="Invoices">
            <Invoices />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/refunds`,
        exact: true,
        element: (
          <ViewWrapper title="Refunds">
            <Refunds />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/account/settings`,
        exact: true,
        element: (
          <ViewWrapper title="Settings">
            <Settings />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/account/help`,
        exact: true,
        element: (
          <ViewWrapper title="Help Page">
            <HelpPage />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/vouchers/view-details/:id`,
        exact: true,
        element: (
          <ViewWrapper title="Voucher Details">
            <VoucherDetails />
          </ViewWrapper>
        ),
      },
      { path: `${basePortalURL}/404`, exact: true, element: <Error /> },
      { path: '*', exact: true, element: <Navigate to={`/404`} /> },
    ],
  },
  {
    path: '/',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard>
    ),
    children: [
      { path: `${basePortalURL}/auth/404`, element: <Error /> },
      {
        path: `${basePortalURL}/auth/login`,
        element: (
          <ViewWrapper title="Login">
            <Login />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/auth/set-password`,
        element: (
          <ViewWrapper title="Login" description="Login page">
            <EmployerSetPassword />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/auth/forgot-password`,
        element: (
          <ViewWrapper title="Forgot Password" description="Forgot Password page">
            <ForgotPassword />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/auth/confirm-email`,
        element: (
          <ViewWrapper title="Confirmation Email" description="Confirmation of an Email">
            <ConfirmEmail />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/auth/two-steps`,
        element: (
          <ViewWrapper title="Two Steps" description="Two Steps page">
            <TwoSteps />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/auth/maintenance`,
        element: (
          <ViewWrapper title="Maintence">
            <Maintenance />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/sal-sac-form`,
        exact: true,
        element: (
          <ViewWrapper title="Sign Agreement" description="Sign Agreement page">
            <PdfFormPage />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/retailer_portal/verify-retailer-and-voucher`,
        exact: true,
        element: (
          <ViewWrapper title="Retailer Portal | Verify Voucher" description="Verify voucher page">
            <RetailerPortalVerifyVoucherAndRetailerPage />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/retailer_portal/redeem-voucher`,
        exact: true,
        element: (
          <ViewWrapper title="Retailer Portal | Verify Voucher" description="Verify voucher page">
            <RetailerPortalRedeemVoucherPage />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/retailer_portal/register`,
        exact: true,
        element: (
          <ViewWrapper
            title="Retailer Portal Registration"
            description="This is a retailer portal registration page"
          >
            <RetailerPortalRegistrationPage />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/retailer_portal/confirm-pickup`,
        exact: true,
        element: (
          <ViewWrapper title="Retailer Portal | Verify Voucher" description="Verify voucher page">
            <RetailerPortalConfirmPickupPage />
          </ViewWrapper>
        ),
      },
      {
        path: `${basePortalURL}/employer_portal/register`,
        exact: true,
        element: (
          <ViewWrapper
            title="Employer Sign-up | gogeta | Cycle to Work Scheme"
            description="This is a retailer portal registration page"
          >
            <EmployerPortalRegistrationPage />
          </ViewWrapper>
        ),
      },
      ...b2bPages,
    ],
  },
];
