const PARAGRAPH_FONT_FAMILY = 'Geomanist';
const HEADING_FONT_FAMILY = 'DTGetaiGroteskDisplay';
const BUTTON_FONT_FAMILY = 'GeomanistMedium';
const REGULAR_FONT_FAMILY = 'GeomanistRegular';
const BOLD_FONT_FAMILY = 'GeomanistBold';
const BOOK_FONT_FAMILY = 'GeomanistBook';

export const defaultThemeFontsNewDesign = {
  hero: {
    large: `900 130px / 110px ${HEADING_FONT_FAMILY}`,
    medium: `800 80px / 70px ${HEADING_FONT_FAMILY}`,
  },
  largeHeading: {
    xxl: `900 320px / 486px ${HEADING_FONT_FAMILY}`,
    xl: `900 240px / 207px ${HEADING_FONT_FAMILY}`,
    large: `900 180px / 170px ${HEADING_FONT_FAMILY}`,
    largeMedium: `900 150px / 128px ${HEADING_FONT_FAMILY}`,
    largeSmall: `900 90px / 86px ${HEADING_FONT_FAMILY}`,
    mediumLarge: `900 140px / 100px ${HEADING_FONT_FAMILY}`,
    medium: `900 120px / 100px ${HEADING_FONT_FAMILY}`,
    small: `900 89px / 76px ${HEADING_FONT_FAMILY}`,
    tiny: `900 60px / 76px ${HEADING_FONT_FAMILY}`,
    smallDesktop: `900 160px / 125px ${HEADING_FONT_FAMILY}`,
  },
  heading: {
    xl: `800 80px / 85px ${HEADING_FONT_FAMILY}`,
    enormous: `900 70px / 75px ${HEADING_FONT_FAMILY}`,
    large: `800 60px / 65px ${HEADING_FONT_FAMILY}`,
    mediumXl: `800 50px / 56px ${HEADING_FONT_FAMILY}`,
    mediumLarge: `800 40px / 46px ${HEADING_FONT_FAMILY}`,
    medium: `800 40px / 42px ${HEADING_FONT_FAMILY}`,
    small: `900 37px / 39px ${HEADING_FONT_FAMILY}`,
    tiny: `900 28px / 26px ${HEADING_FONT_FAMILY}`,
    xs: `900 23px / 26px ${HEADING_FONT_FAMILY}`,
    xss: `900 19px / 20px ${HEADING_FONT_FAMILY}`,
    card: `900 50px / 56px ${HEADING_FONT_FAMILY}`,
    cardMedium: `900 46px / 55px ${HEADING_FONT_FAMILY}`,
    cardSmall: `900 32px / 48px ${HEADING_FONT_FAMILY}`,
  },
  paragraph: {
    xl: `400 31px / 35px ${REGULAR_FONT_FAMILY}`,
    enormous: `400 28px / 35px ${REGULAR_FONT_FAMILY}`,
    large: `400 24px / 36px ${REGULAR_FONT_FAMILY}`,
    mediumPlus: `400 24px / 28px ${REGULAR_FONT_FAMILY}`,
    medium: `400 24px / 28px ${REGULAR_FONT_FAMILY}`,
    small: `400 15px / 18px ${REGULAR_FONT_FAMILY}`,
  },
  text: {
    xl: `400 32px / 60px ${BOOK_FONT_FAMILY}`,
    enormous: `400 28px / 60px ${BOOK_FONT_FAMILY}`,
    medium: `400 20px / 40px ${BOOK_FONT_FAMILY}`,
    small: `400 15px / 20px ${BOOK_FONT_FAMILY}`,
  },
  button: {
    largeThin: `400 19px / 24px ${PARAGRAPH_FONT_FAMILY}`,
    large: `500 19px / 24px ${BUTTON_FONT_FAMILY}`,
    medium: `500 16px / 24px ${BUTTON_FONT_FAMILY}`,
    mediumSecond: `500 16px / 20px ${BUTTON_FONT_FAMILY}`,
    small: `500 12px / 12px ${BUTTON_FONT_FAMILY}`,
    disabledLabelLarge: `400 19px / 24px ${PARAGRAPH_FONT_FAMILY}`,
    disabledLabelSmall: `400 12px / 12px ${PARAGRAPH_FONT_FAMILY}`,
  },
  header: {
    link: `400 19px / normal ${PARAGRAPH_FONT_FAMILY}`,
    dropdown: `400 19px / normal ${REGULAR_FONT_FAMILY}`,
  },
  footer: {
    address: `400 23px / 30px ${REGULAR_FONT_FAMILY}`,
    link: `400 16px / 26px ${REGULAR_FONT_FAMILY}`,
    title: `700 19px / 42px ${BUTTON_FONT_FAMILY}`,
    smallTitle: `700 23px / 9.4px ${BUTTON_FONT_FAMILY}`,
    smallLink: `400 16px / 20px ${REGULAR_FONT_FAMILY}`,
    smallText: `400 10px / 12px ${PARAGRAPH_FONT_FAMILY}`,
    mobileLink: `700 23px / 23px ${BUTTON_FONT_FAMILY}`,
  },
  slider: {
    title: `700 31px / 35px ${HEADING_FONT_FAMILY}`,
    titleMedium: `700 28px / 35px ${HEADING_FONT_FAMILY}`,
  },
  testimonials: {
    largeBold: `400 19px / 24px ${BUTTON_FONT_FAMILY}`,
    large: `400 19px / 24px ${PARAGRAPH_FONT_FAMILY}`,
    small: `400 15px / 18px ${PARAGRAPH_FONT_FAMILY}`,
    smallBold: `700 15px / 18px ${BUTTON_FONT_FAMILY}`,
  },
  switcher: {
    large: `700 19px / 24px ${BUTTON_FONT_FAMILY}`,
    small: `700 10px / 12px ${BUTTON_FONT_FAMILY}`,
  },
  input: {
    large: `900 50px / 56px ${HEADING_FONT_FAMILY}`,
    small: `900 31px / 28px ${HEADING_FONT_FAMILY}`,
    largeMobile: `900 19.5px / 20.5px ${HEADING_FONT_FAMILY}`,
    smallMobile: `900 12px / 10px ${HEADING_FONT_FAMILY}`,
    largePlaceholder: `500 21px / 21px ${BUTTON_FONT_FAMILY}`,
    mediumPlaceholder: `500 18px / 18px ${BUTTON_FONT_FAMILY}`,
    smallPlaceholder: `500 16px / 16px ${BUTTON_FONT_FAMILY}`,
  },
  calculator: {
    xl: `700 31px / 35px ${BUTTON_FONT_FAMILY}`,
    enormous: `700  28px / 32px ${BUTTON_FONT_FAMILY}`,
    large: `700 24px / 32px ${BUTTON_FONT_FAMILY}`,
    small: `700 15px / 18px ${BUTTON_FONT_FAMILY}`,
    largeText: `400 19px / 24px ${REGULAR_FONT_FAMILY}`,
    smallText: `400 10px / 12px ${REGULAR_FONT_FAMILY}`,
  },
  accordion: {
    large: `700 50px / 60px ${BUTTON_FONT_FAMILY}`,
    enormous: `700 40px / 60px ${BUTTON_FONT_FAMILY}`,
    xlText: `500 24px / 35px ${REGULAR_FONT_FAMILY}`,
    largeText: `500 18px / 27px ${REGULAR_FONT_FAMILY}`,
    smallText: `500 15px / 15px ${REGULAR_FONT_FAMILY}`,
    small: `700 13px / 15px ${BUTTON_FONT_FAMILY}`,
  },
  map: {
    title: `700 19px / 24px ${BUTTON_FONT_FAMILY}`,
    text: `400 19px / 24px ${PARAGRAPH_FONT_FAMILY}`,
    smallTitle: `700 12px / 14px ${BUTTON_FONT_FAMILY}`,
    smallText: `400 12px / 14px ${PARAGRAPH_FONT_FAMILY}`,
  },
  card: {
    labelLarge: `900 40px / 44px ${HEADING_FONT_FAMILY}`,
    titleLarge: `400 24px / 36px ${BOOK_FONT_FAMILY}`,
    textLarge: `400 18px / 27px ${REGULAR_FONT_FAMILY}`,
    buttonLarge: `500 16px / 20px ${REGULAR_FONT_FAMILY}`,
    labelMedium: `900 38px / 40px ${HEADING_FONT_FAMILY}`,
    titleMedium: `400 22px / 32px ${BOOK_FONT_FAMILY}`,
    textMedium: `400 16px / 24px ${REGULAR_FONT_FAMILY}`,
    buttonMedium: `500 12px / 12px ${REGULAR_FONT_FAMILY}`,
  },
  aboutUs: {
    smallText: `400 18px / 28px ${PARAGRAPH_FONT_FAMILY}`,
    smallTitle: `700 24px / 24px ${BUTTON_FONT_FAMILY}`,
  },
  tell: {
    updateTitle: `700 36px / 60px ${BUTTON_FONT_FAMILY}`,
    smallText: `400 18px / 28px ${REGULAR_FONT_FAMILY}`,
  },
  faq: {
    title: `700 60px / 85px ${HEADING_FONT_FAMILY}`,
    label: `700 32px / 40px ${HEADING_FONT_FAMILY}`,
  },
  faqAccordionHeading: {
    large: `700 50px / 60px ${BOLD_FONT_FAMILY}`,
    enormous: `700 40px / 60px ${BOLD_FONT_FAMILY}`,
    enormousText: `500 24px / 36px ${REGULAR_FONT_FAMILY}`,
    xlText: `500 24px / 30px ${BOLD_FONT_FAMILY}`,
    largeText: `500 18px / 27px ${BOLD_FONT_FAMILY}`,
    small: `700 16px / 15px ${BOLD_FONT_FAMILY}`,
  },
};
